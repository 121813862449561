<template>
  <div v-if="mobile" class="w-full justify-between text-blue-dark py-4 appearance-none uppercase">
    <div class="pr-3 font-sans-bold text-xl mx-auto text-center mb-8 mt-10">Sortera</div>
    <div class="relative flex flex-wrap border-t border-b border-solid border-grey-lighter">
      <select
        id="sorting"
        @change="$emit('update:sorting', $event.target.value)"
        class="block appearance-none w-full bg-transparent py-4 pr-12 focus:outline-none font-sans-bold text-sm text-blue-dark uppercase cursor-pointer"
      >
        <option v-for="(option, value) in options" :key="value" :value="option.value" :selected="option.value === sorting">{{ option.title }}</option>
      </select>
      <div class="pointer-events-none absolute pin-y pin-r flex items-center text-grey-dark">
        <span class="h-4 inline-block text-grey-dark">
          <svg class="stroke-current h-3 w-3" viewBox="0 0 768 768">
            <path fill="none" stroke-linejoin="miter" stroke-linecap="square" stroke-miterlimit="4" stroke-width="96" d="M95.999 240l288.001 288.001 288.001-288.001"></path>
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div v-else class="mb-0 px-3 w-1/2h hidden lg:flex flex-wrap items-center justify-center md:justify-end">
    <label for="sorting" class="pr-3 small-text">Sortera</label>
    <div class="relative max-w-xxxs">
      <select
        id="sorting"
        @change="$emit('update:sorting', $event.target.value)"
        class="block appearance-none w-full bg-transparent pr-12 focus:outline-none font-sans-bold text-sm font-normal leading-loose cursor-pointer"
      >
        <option v-for="(option, value) in options" :key="value" :value="option.value" :selected="option.value === sorting">{{ option.title }}</option>
      </select>
      <div class="pointer-events-none absolute pin-y pin-r flex items-center text-grey-dark">
        <span class="h-4 inline-block text-grey-dark">
          <svg class="stroke-current h-3 w-3" viewBox="0 0 768 768">
            <path fill="none" stroke-linejoin="miter" stroke-linecap="square" stroke-miterlimit="4" stroke-width="96" d="M95.999 240l288.001 288.001 288.001-288.001"></path>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'SearchSorting',
  props: {
    'sorting': {},
    'options': {},
    'mobile': {
      default: false
    }
  },
  options: {
    type: Object,
    default: [],
    required: true,
  }
};
</script>
