var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
        staticClass: "mb-3 md:mb-4 w-full",
      },
      [
        _c(
          "a",
          {
            staticClass:
              "card-shadow text-blue-darker group block w-full h-full rounded-md overflow-hidden bg-white p-6 flex items-center",
            attrs: { href: _vm.item.materialLink[0].linkUrl },
          },
          [
            _vm.item.materialLink[0].linkUrl.endsWith(".pdf")
              ? _c("span", { staticClass: "block mr-5 text-blue" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "44",
                        height: "44",
                        viewBox: "0 0 44 44",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          width: "44",
                          height: "44",
                          rx: "8",
                          fill: "#F4F1EE",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M23.586 12C24.0556 12.0001 24.5101 12.1654 24.87 12.467L25 12.586L29.414 17C29.746 17.332 29.9506 17.7703 29.992 18.238L30 18.414V30C30.0002 30.5046 29.8096 30.9906 29.4665 31.3605C29.1234 31.7305 28.6532 31.9572 28.15 31.995L28 32H16C15.4954 32.0002 15.0094 31.8096 14.6395 31.4665C14.2695 31.1234 14.0428 30.6532 14.005 30.15L14 30V14C13.9998 13.4954 14.1904 13.0094 14.5335 12.6395C14.8766 12.2695 15.3468 12.0428 15.85 12.005L16 12H23.586ZM22 14H16V30H28V20H23.5C23.1271 20 22.7676 19.8611 22.4916 19.6104C22.2156 19.3597 22.0428 19.0152 22.007 18.644L22 18.5V14ZM22.988 21.848C23.2274 23.3711 24.0239 24.7509 25.223 25.72C26.11 26.437 25.299 27.841 24.235 27.432C22.7965 26.8781 21.2035 26.8781 19.765 27.432C18.7 27.842 17.889 26.437 18.776 25.72C19.9751 24.7509 20.7716 23.3711 21.011 21.848C21.189 20.721 22.811 20.722 22.988 21.848ZM21.998 24.152L21.31 25.348H22.69L21.998 24.152ZM24 14.414V18H27.586L24 14.414Z",
                          fill: "#13284B",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm.item.materialLink[0].linkUrl.startsWith("http://") ||
                _vm.item.materialLink[0].linkUrl.startsWith("https://")
              ? _c("span", { staticClass: "block mr-5 text-blue" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "44",
                        height: "44",
                        viewBox: "0 0 44 44",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          width: "44",
                          height: "44",
                          rx: "8",
                          fill: "#F4F1EE",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M22 31C23.1819 31 24.3522 30.7672 25.4442 30.3149C26.5361 29.8626 27.5282 29.1997 28.364 28.364C29.1997 27.5282 29.8626 26.5361 30.3149 25.4442C30.7672 24.3522 31 23.1819 31 22C31 20.8181 30.7672 19.6478 30.3149 18.5558C29.8626 17.4639 29.1997 16.4718 28.364 15.636C27.5282 14.8003 26.5361 14.1374 25.4442 13.6851C24.3522 13.2328 23.1819 13 22 13M22 31C20.8181 31 19.6478 30.7672 18.5558 30.3149C17.4639 29.8626 16.4718 29.1997 15.636 28.364C14.8003 27.5282 14.1374 26.5361 13.6851 25.4442C13.2328 24.3522 13 23.1819 13 22C13 20.8181 13.2328 19.6478 13.6851 18.5558C14.1374 17.4639 14.8003 16.4718 15.636 15.636C16.4718 14.8003 17.4639 14.1374 18.5558 13.6851C19.6478 13.2328 20.8181 13 22 13M22 31C24.761 31 25.941 25.837 25.941 22C25.941 18.163 24.761 13 22 13M22 31C19.239 31 18.059 25.837 18.059 22C18.059 18.163 19.239 13 22 13M13.5 19H30.5M13.5 25H30.5",
                          stroke: "#13284B",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm.item.materialLink[0].linkUrl.endsWith(".jpg") ||
                _vm.item.materialLink[0].linkUrl.endsWith(".jpeg") ||
                _vm.item.materialLink[0].linkUrl.endsWith(".png") ||
                _vm.item.materialLink[0].linkUrl.endsWith(".gif")
              ? _c("span", { staticClass: "block mr-5 text-blue" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "44",
                        height: "44",
                        viewBox: "0 0 44 44",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          width: "44",
                          height: "44",
                          rx: "8",
                          fill: "#F4F1EE",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M29 13H15C13.8954 13 13 13.8954 13 15V29C13 30.1046 13.8954 31 15 31H29C30.1046 31 31 30.1046 31 29V15C31 13.8954 30.1046 13 29 13Z",
                          stroke: "#13284B",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21Z",
                          stroke: "#13284B",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M31 24.9997L27.914 21.9137C27.5389 21.5388 27.0303 21.3281 26.5 21.3281C25.9697 21.3281 25.4611 21.5388 25.086 21.9137L16 30.9997",
                          stroke: "#13284B",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ])
              : _c("span", { staticClass: "block mr-5 text-blue" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "44",
                        height: "44",
                        viewBox: "0 0 44 44",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          width: "44",
                          height: "44",
                          rx: "8",
                          fill: "#F4F1EE",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M22 13V19C22 19.2652 22.1054 19.5196 22.2929 19.7071C22.4804 19.8946 22.7348 20 23 20H29",
                          stroke: "#13284B",
                          "stroke-width": "2",
                          "stroke-linejoin": "round",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M15 16C15 14.3431 16.3431 13 18 13H20.7574C21.553 13 22.3161 13.3161 22.8787 13.8787L25.5 16.5L28.1213 19.1213C28.6839 19.6839 29 20.447 29 21.2426V28C29 29.6569 27.6569 31 26 31H18C16.3431 31 15 29.6569 15 28V16Z",
                          stroke: "#13284B",
                          "stroke-width": "2",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass:
                  "font-sans font-normal text-sm tracking-slight leading-tight mr-5",
              },
              [_vm._v(_vm._s(_vm.item.title))]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "font-sans text-blue-accessible ml-auto mb-0 flex items-center",
              },
              [
                _c("span", { staticClass: "hidden md:block" }, [
                  _vm._v(_vm._s(_vm.item.materialLink[0].linkText)),
                ]),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "hidden md:block ml-2-5 flex-no-shrink",
                    attrs: {
                      width: "7",
                      height: "12",
                      viewBox: "0 0 7 12",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M1.27184 1.2721L5.99974 6L1.27184 10.7279",
                        stroke: "#0178CD",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "md:hidden ml-2-5 flex-no-shrink",
                    attrs: {
                      width: "15",
                      height: "16",
                      viewBox: "0 0 15 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M4.5641 3.37145L9.1282 7.93555L4.5641 12.4996",
                        stroke: "#0178CD",
                        "stroke-width": "2",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }