var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.sectionType[0] === "events"
        ? [
            _c(
              "div",
              {
                style: {
                  minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto",
                },
              },
              [
                _c("div", {
                  staticClass:
                    "mb-9 pb-3 border-b border-1 border-solid border-grey-light",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "relative max-w-md xxl:max-w-full mx-auto" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "absolute w-full h-full",
                        staticStyle: { "z-index": "-1" },
                      },
                      _vm._l(3, function (n) {
                        return _c("event-card-loading-indicator", {
                          key: n,
                          attrs: {
                            "is-loading": _vm.isLoading,
                            "is-ready": _vm.isReady,
                          },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _vm.items.length > 0
                      ? [
                          _c(
                            "ul",
                            { staticClass: "list-reset" },
                            _vm._l(_vm.items, function (item, key) {
                              return _c("event-search-item", {
                                key: key,
                                attrs: {
                                  item: item,
                                  "is-loading": _vm.isLoading,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { name: "fadeIn", mode: "in-out" } },
                      [
                        !_vm.isLoading && _vm.items.length === 0
                          ? [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "heading-4 uppercase mx-auto py-8 text-center",
                                },
                                [_vm._v("Inga resultat hittades")]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]
        : _vm.sectionType[0] === "material"
        ? [
            _c(
              "div",
              {
                staticClass: "max-w-150 relative flex flex-col mx-auto",
                staticStyle: { overflow: "hidden" },
                style: {
                  minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full h-full pin-x mx-auto flex flex-wrap content-start",
                    staticStyle: { "z-index": "-1" },
                  },
                  _vm._l(8, function (n) {
                    return _c("card-loading-indicator", {
                      key: n,
                      attrs: {
                        "is-loading": _vm.isLoading,
                        "is-ready": _vm.isReady,
                        "section-type": _vm.sectionType,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _vm.items.length > 0
                  ? _vm._l(_vm.items, function (item) {
                      return _c("material-search-item", {
                        key: item.id,
                        attrs: {
                          item: item,
                          "is-loading": _vm.isLoading,
                          "section-type": _vm.sectionType,
                        },
                      })
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fadeIn", mode: "in-out" } },
                  [
                    !_vm.isLoading && _vm.items.length === 0
                      ? [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "heading-4 uppercase mx-auto py-8 text-center",
                            },
                            [_vm._v("Inga resultat hittades")]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              2
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass:
                  "relative flex flex-wrap -mx-4 border-b border-1 border-solid border-grey-light",
                staticStyle: { overflow: "hidden" },
                style: {
                  minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full h-full pin-x mx-auto flex flex-wrap content-start",
                    staticStyle: { "z-index": "-1" },
                  },
                  _vm._l(8, function (n) {
                    return _c("card-loading-indicator", {
                      key: n,
                      attrs: {
                        "is-loading": _vm.isLoading,
                        "is-ready": _vm.isReady,
                        "section-type": _vm.sectionType,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _vm.items.length > 0
                  ? _vm._l(_vm.items, function (item) {
                      return _c("category-search-item", {
                        key: item.id,
                        attrs: {
                          item: item,
                          "is-loading": _vm.isLoading,
                          "section-type": _vm.sectionType,
                          "selected-category": _vm.selectedCategory,
                        },
                      })
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fadeIn", mode: "in-out" } },
                  [
                    !_vm.isLoading && _vm.items.length === 0
                      ? [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "heading-4 uppercase mx-auto py-8 text-center",
                            },
                            [_vm._v("Inga resultat hittades")]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }