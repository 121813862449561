<template>
  <div class="-mx-3 hidden lg:flex flex-wrap md:justify-between text-center md:text-left" :class="sectionType[0] === 'events' || sectionType[0] === 'searchLanding' ? 'mb-0' : 'mb-3'">
    <div class="px-3 w-1/2 text-left" :class="sectionType[0] === 'events' || sectionType[0] === 'searchLanding' ? 'mb-0' : 'mb-3 md:mb-0'">
      <div v-if="itemsAmount > 0">
        <span class="pr-1 small-text-bold">{{itemOffset + 1}} – {{itemsAmount}}</span> <span class="small-text">(av {{totalItems}})</span>
      </div>
      <div v-else>
        <span class="pr-1 small-text-bold">0</span> <span class="small-text">(av 0)</span>
      </div>
    </div>
    <search-sorting
      :sorting.sync="searchSorting"
      :options="sortingOptions"
    ></search-sorting>
  </div>
</template>

<script>
import Vue from 'vue';
import SearchSorting from './SearchSorting'

export default {
  name: 'CategorySearchMeta',
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    itemOffset: {
      type: Number,
      default: 0
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    'sectionType': {
      type: Array,
      default: []
    },
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Nyaste först',
          value: 'postdate desc'
        },
        {
          title: 'Äldsta först',
          value: 'postdate asc'
        },
        {
          title: 'Rubrik A-Ö',
          value: 'title asc'
        },
        {
          title: 'Rubrik Ö-A',
          value: 'title desc'
        }
      ]
    }
  },
  data() {
    return {
      searchSorting: ''
    }
  },
  computed: {
    itemsAmount: function() {
     const itemsLeft = this.totalItems - this.itemOffset > this.elementsPerPage ? this.itemOffset + this.elementsPerPage : this.totalItems;

     return itemsLeft;
    }
  },
  watch: {
    searchSorting: function(val) {
      this.$emit('update:searchSorting', val)
    }
  },
  components: {
    SearchSorting
  },
};
</script>
